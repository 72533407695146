import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function Security() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <h2 className="privacy_entry">
          情報セキュリティ<span>SECURITY</span>
        </h2>
        <div className="privacy1_box">
          <Link title="サイバーコム株式会社へ行く" to="/">
            ホーム
          </Link>
          　&nbsp;&gt;&nbsp; 情報セキュリティ
        </div>
        <h3 className="pgtop">情報セキュリティ基本方針</h3>
        <div className="privacy_body">
          <div className="privacy_body">
            <p>
              当社は、より良いサービスを提供し続ける企業として、お客様やお取引先様の情報資産および当社が保有する情報資産を適切に保護し、適正に利用することが社会的責務であると考えます。
              <br />
              ここに「情報セキュリティ基本方針」を以下の通り定め、活動することを宣言します。
            </p>
            <ul>
              <li>
                当社は、情報セキュリティ管理体制を確立し、情報資産の適正管理に努めます。
              </li>
              <li>
                当社は、事業の継続とお客様の信頼に応えるために、情報資産の機密性、完全性、可用性の確保に努めます。
              </li>
              <li>
                当社は、お客様との約束、情報セキュリティに関連する法令及びその他の規範を遵守します。
              </li>
              <li>
                当社は、脅威に備え人的・組織的・物理的・技術的施策を講じ情報資産の保護に努めます。
              </li>
              <li>
                当社の情報資産を利用する全従業者は、本方針の趣旨を理解し情報セキュリティ関連規則を遵守します。
              </li>
              <li>当社は、以上の活動を継続的に見直し改善に努めます。</li>
            </ul>
            <p class="tright premr02">
              制定日：2012年3月26日
              <br />
              改定日：2015年4月14日
              <br />
              <br />
              サイバーコム株式会社
              <br />
              代表取締役社長　
              <img
                decoding="async"
                src="	https://www.cy-com.co.jp/corporate/wp-content/files/daihyo-name-2023.png"
                alt="代表取締役"
              />
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Security;
