import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function Privacy() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <h2 className="privacy_entry">
          情報セキュリティ<span>SECURITY</span>
        </h2>
        <div className="privacy1_box">
          <Link title="サイバーコム株式会社へ行く" to="/">
            ホーム
          </Link>
          　&nbsp;&gt;&nbsp; 個人情報保護
        </div>
        <h3 className="pgtop">個人情報保護</h3>
        <div className="privacy_body">
          <div className="privacy_body">
            <h4>個人情報保護方針</h4>
            <p className="premr01">
              サイバーコム株式会社（以下「当社」といいます）は、個人情報保護に関する法令およびその他の規範を遵守し、
              <br />
              ルールおよび体制を確立し、
              社会の健全な進展に資するよう取り組むことを誓います。
              <br />
              方針を具体化するため以下の活動を行います。
              <br />
            </p>
            <ul>
              <li>
                当社は個人情報の取得にあたり、お客様に対し取得目的を明確に特定し、
                <br />
                取得した個人情報の利用、提供範囲を限定し、適切に取り扱うための措置を講じます。
              </li>
              <li>
                役員および全ての従業員は、個人情報の取り扱いに関する法令、
                <br />
                国が定める指針その他の規範を遵守します。
              </li>
              <li>
                当社は、個人情報漏えい、滅失またはき損などといったトラブルを引き起こさないように、
                <br />
                社内規定を整備し、合理的かつ適切な安全対策並びに是正を行います。
              </li>
              <li>
                当社は個人情報保護マネジメントシステムを継続的に改善します。
              </li>
              <li>
                当社は個人情報に関する苦情・相談の窓口を設け、迅速かつ的確に対応します。
              </li>
            </ul>
            <p class="tright premr02">
              制定日：2005年4月1日
              <br />
              改定日：2020年6月1日
              <br />
              サイバーコム株式会社
              <br />
              代表取締役社長　
              <img
                decoding="async"
                src="https://www.cy-com.co.jp/corporate/wp-content/files/daihyo-name-2023.png"
                alt="代表取締役"
              />
            </p>
            <h5>個人情報保護管理者の氏名</h5>
            <div className="privacy-col-sm-9">
              サイバーコム株式会社 取締役　臼井 博幸
              <h5>個人情報お問い合わせ・苦情・相談総合窓口</h5>
              <div class="privacy_row">
                <div class="privacy-col-sm-10">
                  {" "}
                  サイバーコム株式会社 技術管理部
                  <br />
                  部長　井料 将人
                  <br />
                  <br />
                  〒231-0005　神奈川県横浜市中区本町4-34　横浜本社ビル
                  <br />
                  TEL：045-681-6001（代）（受付時間：9:00～17:30）
                  <br />
                  <br />
                  <p class="info01">
                    土・日曜日、祝日、年末年始、ゴールデンウィーク、夏期休暇（8月中旬）期間は、翌営業日以降対応とさせていただきます。
                  </p>
                  E-mail： sky@loopyi.cfd
                </div>
                <div class="col-sm-3 tcenter">
                  <img
                    decoding="async"
                    src="https://www.cy-com.co.jp/corporate/wp-content/files/p-mark.png"
                    alt="プライバシーマーク"
                  />
                </div>
              </div>
              <h4>
                個人情報の取り扱いについて
                <label for="checkall">
                  <span class="btn">すべての内容を表示</span>
                </label>
              </h4>
              <p class="premr01">
                サイバーコム株式会社（以下「当社」といいます）は、個人情報をご提供いただくにあたり、当社の個人情報保護方針にもとづき適切に取り扱うことを以下の通りお知らせいたします。
                当社が取得した個人情報は、第三者が不当に触れることがないよう、組織的・人的・技術的な安全管理体制のもと、保管いたします。
              </p>
              <h5>利用目的</h5>
              取得した個人情報は、下記の目的で利用いたします。利用目的以外での使用はいたしません。
              <ul>
                <li>
                  <div class="cp_actab">
                    <label for="p01">
                      <strong>株主様に関する個人情報</strong>
                    </label>
                    <div class="cp_actab-content">
                      <ol>
                        <li>法令に基づく権利の行使および義務の履行のため</li>
                        <li>
                          株主としての地位に対し、当社から各種便宜を供与するため
                        </li>
                        <li>
                          「株式会社の構成員」と「株式会社」という観点から、双方の関係を円滑にするための各種方策を実施するため
                        </li>
                        <li>
                          各種法令に基づく所定の基準による株主様のデータを作成するなど、株主管理のため
                        </li>
                        <li>上記事項に伴うご連絡のため</li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="cp_actab">
                    <label for="p02">
                      <strong>
                        採用応募者またはインターンシップ参加者に関する個人情報
                      </strong>
                    </label>
                    <div class="cp_actab-content">
                      <ol>
                        <li>
                          採用活動またはインターンシップの一環としての資料の送付や面接・実施日程等に関する各種事務連絡
                        </li>
                        <li>
                          採用またはインターンシップにおける選考考査や応募歴等の確認
                        </li>
                        <li>
                          内定承諾後の各種事務連絡および雇用に関する手続き
                        </li>
                        <li>
                          採用活動またはインターンシップに関する社内分析や社内広報
                        </li>
                        <li>
                          その他適切かつ円滑な採用活動またはインターンシップの実施
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="cp_actab">
                    <label for="p03">
                      <strong>協力会社様に関する個人情報</strong>
                    </label>
                    <div class="cp_actab-content">
                      <ol>
                        <li>ご提案いただく方の受付け</li>
                        <li>ご提案いただく方の受入れおよび管理</li>
                        <li>
                          ご提案いただく方が業務に就いていただくにあたっての機密保持誓約
                        </li>
                        <li>
                          ご提案いただく方の業務提案および就いていただく業務の遂行
                        </li>
                        <li>
                          弊社お客様との契約や法律などに基づく権利の行使や義務の遂行
                        </li>
                        <li>上記事項に伴う業務連絡</li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="cp_actab">
                    <label for="p04">
                      <strong>セミナー参加者様に関する個人情報</strong>
                    </label>
                    <div class="cp_actab-content">
                      <ol>
                        <li>申込者へのご連絡と資料の送付</li>
                        <li>
                          次回セミナーなどを開催するための社内資料を作成するため
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="cp_actab">
                    <label for="p05">
                      <strong>
                        従業者（役員・従業員のご家族・退職者を含む）に関する個人情報
                      </strong>
                    </label>
                    <div class="cp_actab-content">
                      {" "}
                      ●役員・従業員
                      <ol>
                        <li>
                          賃金、賞与、退職金などの決定・支払い（賃金、賞与、諸手当などの決定および支払い、その他報酬支払い上の必要な手続き）
                        </li>
                        <li>
                          人事労務管理（人事考課、配属先の決定、昇進昇格、出向、休職、復職、表彰、懲戒、退職、解雇、その他人事管理上の必要な手続き）
                        </li>
                        <li>
                          人材育成（教育研修情報の提供、育成計画の作成、その他教育研修機会の提供のための必要な手続き）
                        </li>
                        <li>
                          健康管理（健康の維持増進及び安全管理の確保のための施策の立案、会社として実施すべき安全配慮義務の履行と就業上の措置決定等）
                        </li>
                        <li>
                          社会保険・税金等手続（健康保険、厚生年金保険（企業年金）、労働保険手続き、所得税源泉徴収事務等）
                        </li>
                        <li>
                          労働者災害補償保険法に基づく請求に関する事務手続
                        </li>
                        <li>
                          福利厚生（会社福利厚生制度の実施管理、各種制度加入者管理、給与・賞与控除の実施等）
                        </li>
                        <li>
                          防犯管理（当社施設内の防犯・安全管理、入退記録ならびに防犯カメラの撮影映像等）
                        </li>
                        <li>
                          採用活動（卒業校への情報提供、当社ホームページへの公開等）
                        </li>
                        <li>
                          その他（法令もしくはそれに準じるものに定める事項の遵守、業務上及び緊急時における連絡、情報システムへのアクセス権限管理、システム監視ログ等セキュリティに関する事項、本項に記載した利用目的の達成のための個人データの第三者への提供、会社諸規則に定める事項の遂行にあたり個人情報保護に関する法令及び会社規則に明らかに反しない目的等）
                        </li>
                      </ol>
                      ●従業員のご家族
                      <ol>
                        <li>非常時の安否確認や緊急な連絡等</li>
                        <li>従業員への給与・諸手当等の算定、支払い</li>
                        <li>福利厚生・年金等の管理</li>
                      </ol>
                      ●退職者
                      <ol>
                        <li>退職者に対する諸手続き・連絡</li>
                        <li>社会保険関係に係る各種問い合わせ対応</li>
                        <li>
                          在職中における勤務・給与・健康情報等の記録の管理
                        </li>
                      </ol>
                    </div>
                  </div>
                </li>
                <li>
                  <strong>
                    その他当社へのお問い合わせの際に提供された個人情報
                  </strong>
                  <br />
                  お問い合わせへの回答・連絡および対応上必要な手続きのため
                </li>
                <li>
                  <strong>
                    他の事業者などから個人情報の処理の委託を受けた場合
                  </strong>
                  <br />
                  委託された当該業務を適切に遂行するため
                  <br />
                  （委託された業務の実施に必要となる範囲内で利用いたします）
                </li>
              </ul>
              <h5>要配慮個人情報の取得、利用</h5>
              <p>
                当社は、原則として要配慮個人情報の取得、利用、提供を行いません。
                ただし当社の採用活動、また社員情報の管理にあたっては、利用目的の範囲内で以下の個人情報のご提出をお願いすることがあります。
              </p>
              <br />
              ●従業者に関する個人情報（従業員のご家族は除く）
              <ol class="secoplst">
                <li>
                  健康診断の実施および結果に関する情報（健康診断結果など）
                </li>
                <li>保険医療に関する情報（休職者情報、診断書など）</li>
                <li>身体・精神障害に関する情報（障害者手帳、診断書など）</li>
                <li>国籍に関する情報（ビザのコピーなど）</li>
              </ol>
              <h5>第三者への開示・提供</h5>
              <ol class="secoplst">
                <li>
                  当社は、ご提供いただいた個人情報を、以下のいずれかに該当する場合を除き、第三者に対して開示いたしません。
                  <ul>
                    <li>既にご本人の同意を得ている場合</li>
                    <li>法令に基づく場合</li>
                    <li>
                      人の生命、身体又は財産の保護のために必要であって、ご本人の同意を取ることが困難な場合
                    </li>
                    <li>
                      利用目的の達成に必要な範囲で、個人情報の取り扱いを委託する場合
                    </li>
                    <li>
                      合併、会社分割、営業譲渡その他の事由によって事業の承継が行われる場合
                    </li>
                  </ul>
                </li>
                <li>
                  当社は、当社のグループ会社や第三者より対応させていただくことが適切と判断される場合に限り、ご本人の同意を得たうえで第三者に開示または提供することがあります。
                </li>
              </ol>
              <h5>個人情報の安全管理措置</h5>
              <p>
                当社は、当社が保有する個人データに関わる安全管理措置を以下の通り適切に実施しています。
              </p>
              <p>
                <strong>●基本方針の策定</strong>
                <br />
                個人データの適正な取扱いの確保のため、個人情報保護関係法令等の遵守、質問及び苦情処理の窓口等についての基本方針を策定しております。（「個人情報保護方針」参照）{" "}
              </p>
              <p>
                <strong>●個人データの取扱いに係る規律の整備</strong>
                <br />
                個人データの取扱方法、責任者・担当者及びその任務等について定めた取扱規程を策定しております。{" "}
              </p>
              <p>
                <strong> ●組織的安全管理措置</strong>
                <br />
                1.
                個人情報管理の責任者として、「個人情報保護管理者」を任命するとともに、個人データを取り扱う従業者の責任と権限を明確に規定します。
                <br />
                従業者を監督し、個人情報保護関連法令や社内規程に違反している事実又は兆候を把握した場合の責任者への報告連絡体制を整備しております。
                <br />
                2.
                個人データの取扱状況について、定期的に自己点検を実施するとともに、他部署や外部の者による監査を実施しております。{" "}
              </p>
              <p>
                <strong>●人的安全管理措置</strong>
                <br />
                1.
                個人データの適切な取り扱いについて、従業者に定期的に教育を実施しております。
                <br />
                2.
                個人データについての秘密保持に関する事項を就業規則に定めております。{" "}
              </p>
              <p>
                <strong>●物理的安全管理措置</strong>
                <br />
                1.
                個人データを取り扱う区域において、従業者の入退室管理及び持ち込む機器等の制限を行うとともに、権限を有しない者による個人データの閲覧を防止する措置を実施しております。
                <br />
                2.
                個人データを取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じております。当該機器、電子媒体等を持ち運ぶ場合、容易に個人データが漏洩しないよう、事業所内の移動を含め措置を実施しております。
              </p>
              <p>
                <strong>●技術的安全管理措置</strong>
                <br />
                1.
                アクセス制御を実施して、担当者及び取り扱う個人情報データベース等の範囲を限定しております。
                <br />
                2.
                個人データを取り扱う情報システムを外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入しております。
                <br />
              </p>
              <h5>個人情報取り扱い業務の外部委託</h5>
              <ol>
                <li>
                  当社では、利用目的の達成に必要な範囲内において個人データの取り扱いの全部または一部を委託先に委託することがあります。その場合、当社の定めた基準にもとづき十分な個人情報の保護水準を満たしているものを選定いたします。
                </li>
                <li>
                  委託した個人情報の安全管理が図られるよう、契約において個人情報の管理、秘密保持、再提供の禁止など、ご本人の個人情報の漏えいなどがないように必要な事項を取り決めるとともに、個人情報の安全管理が図られるよう必要かつ適切な監督を行います。
                </li>
              </ol>
              <h5>保有個人データに関する事項</h5>
              <p>
                原則として個人情報のご本人に限り、「個人情報の利用目的」の通知、登録した個人情報の開示、訂正、追加または削除、利用停止、消去、第三者への提供の停止および第三者提供に係る記録の開示の各請求に対応させていただきます。なお、個人情報および第三者提供に係る記録の開示の請求、利用目的の通知の請求につきましては有料とさせていただきます。
                所定の手続きを通じて、合理的な期間および範囲で対応いたします。但し、法令の定めや調査の結果、開示などを行わないことを決定することがございます。この場合は、その旨理由を付して通知させていただきます。
                これらのご請求または苦情・要望をお申し出になる場合には、該当の窓口までご連絡をお願い申し上げます。
              </p>
              <h5>個人情報提供における任意性</h5>
              <p>
                当社が個人情報を取得する項目は、全て任意です。ただし、ご提供いただけない情報の種類によっては、当社からの連絡の一部又は全部をご提供できない場合があります。
              </p>
              <h5>当Webサイトにおける個人情報などの取り扱いについて</h5>
              <h6>
                <strong>
                  <span style={{ fontSize: "12pt" }}>
                    アクセスログの記録について
                  </span>
                </strong>
              </h6>
              <p>
                当Webサイトではユーザーのアクセス情報をアクセスログに記録しています。
                <br />
                アクセスログには、IPアドレス、ブラウザ名、閲覧先ページのURL等の情報が含まれますが、それ単体で特定の個人を特定できる情報は記録していません。
                <br />
                記録したアクセスログは、当Webサイトの保守管理のみに使用いたします。
              </p>
              <h6>
                <strong>
                  <span style={{ fontSize: "12pt" }}>
                    Cookie及び類似技術の利用について
                  </span>
                </strong>
              </h6>
              <p>
                当Webサイトでは、Cookie（クッキー）およびその類似技術を使用して一定の情報（IPアドレス、当Webサイトの閲覧状況等の情報）を取得しています。
                これらの情報はそれ単体で特定の個人を識別できるものではなく、当社はこれを個人を識別するために利用していません。
              </p>
              <p>
                お使いのブラウザの設定にてクッキーを無効化することで、これらの情報は取得されなくなりますが、Webサイトの閲覧や利用が正常に行えない場合がございますのでご注意ください。
              </p>
              <p>
                Cookieの使用停止方法については、ご使用のブラウザのヘルプをご確認ください。
              </p>
              <p>
                ・Internet Explorer11
                <br />
                ・Microsoft Edge
                <br />
                ・Google Chrome
                <br />
                ・Firefox
                <br />
                ・iPhone、iPad（Safari）
                <br />
              </p>
              <h6>
                <span style={{ fontSize: "12pt" }}>
                  <strong>Cookie及び類似技術を利用したツールと使用目的</strong>
                </span>
              </h6>
              <p>
                <strong>Google Analytics</strong>
                <br />
                当Webサイトは、Google, Inc. の提供する Google Analytics
                を使用して、ユーザのアクセスデータの測定・集計を行っております。
                <br />
                アクセスデータには、アクセスされたページのURL、アクセスされた日時、ブラウザを識別する情報等が含まれますが、特定の個人を識別することができる情報は記録しておりません。
                <br />
                また、それらの情報は、Google.Inc.により同社のプライバシーポリシーに基づいて管理されます。
                <br />
                集計したアクセスデータは、ユーザの利便性向上および本ホームページの品質維持・向上のために利用させていただきます。
              </p>
              <p>
                Google
                Analyticsの利用規約やGoogle.Inc.のプライバシーポリシー等は以下をご参照ください。
              </p>
              <p>
                ・Google ポリシーと規約 プライバシーポリシー
                <br />
                ・Google のサービスを使用するサイトやアプリから収集した情報の
                Google による使用
                <br />
                ・Google Analyticsの利用規約
                <br />
                また、次のリンクからGoogle アナリティクス オプトアウト
                アドオンをインストールすることで、Google
                Analyticsによるアクセス分析を無効化することができます。
              </p>
              <p>・Google Analytics　オプトアウトアドインについて</p>
              <strong>Google広告／Yahoo広告／Meta広告</strong>
              <p>
                当社は、当社のサービスやプロダクトの販売促進活動の目的で広告配信サービスを利用しており、
                これらの第三者がcookie及びその類似技術によってユーザの当ウェブサイトへの訪問・行動履歴情報を取得・利用している場合があります。
                <br />
                当該第三者によって取得された訪問・行動履歴情報は、当該第三者のプライバシーポリシーに従って取り扱われます。
                <br />
                ユーザは、第三者が提供する広告配信サービスのオプトアウト手段により、取得された訪問・行動履歴情報の広告配信への利用を停止できます。
                <br />
                また、事前の同意を得ている場合を除いて、広告配信に顧客情報等の個人情報を使用することはありません。
              </p>
              <strong>Google広告</strong>
              <p>
                ・Google による広告での Cookie の利用方法
                <br />
                ・広告のカスタマイズ
              </p>
              <strong>Yahoo広告</strong>
              <p>
                ・Yahoo! JAPANが配信する広告などへのパーソナルデータの利用
                <br />
                ・行動履歴による広告内容の最適化
              </p>
              <strong>Meta広告（Facebook、Instagram）</strong>
              <p>・プライバシーポリシー</p>
              <h5>当社の所属する認定個人情報保護団体について</h5>
              <p>
                当社は、個人情報保護委員会の認定を受けた「一般財団法人日本情報経済社会推進協会（ＪＩＰＤＥＣ）」の対象事業者です。
                同協会では、対象事業者の個人情報の取り扱いに関する苦情・相談を受け付けております。
              </p>
              <ul>
                <li>
                  <strong>お問い合わせ先</strong>
                  <br />
                  TANYA UUOO LLC
                  <br />
                  認定個人情報保護団体事務局
                  <br />
                  〒1309 Coffeen Avenue STE 1200，Sheridan, Wyoming 82801
                  <br />
                  TEL：(415) 425-9345
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
