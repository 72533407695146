import React, { useState } from "react";
import Footer from "./Footer";
import "../css/contact.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function Contact() {
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    identity: "",
    email: "",
    details: "",
    purpose: false,
    companyName: "",
    department: "",
    zipcode: "",
    prefecture: "",
    city: "",
    street: "",
    buildName: "",
    tel: "",
  });
  const [selectedOption, setSelectedOption] =
    useState("Cyber Smartシリーズ（※）");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    if (!formData.name) errors.name = "必須項目に記入もれがあります。";
    if (!formData.email) errors.email = "必須項目に記入もれがあります。";
    if (!formData.details) errors.details = "必須項目に記入もれがあります。";
    if (!formData.purpose) errors.purpose = "必須項目に記入もれがあります。";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    alert("送信成功！");
    setFormData({
      name: "",
      identity: "",
      email: "",
      details: "",
      purpose: false,
      companyName: "",
      department: "",
      zipcode: "",
      prefecture: "",
      city: "",
      street: "",
      buildName: "",
      tel: "",
    });
    setFormErrors({});
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main-container">
      <Header />
      <div className="contact_box">
        <h2 className="contact_entry">&nbsp;</h2>
        <div className="contact1_box">
          <Link title="サイバーコム株式会社へ行く" to="/">
            ホーム
          </Link>
          　&nbsp;&gt;&nbsp;　お問い合わせ
        </div>
        <div className="contact_body">
          <h3 className="pgtop">お問い合わせ</h3>
          <div className="contact_internal">
            <p>
              お問い合わせ事項がございましたら、下記にご記入の上ご送信ください。
            </p>
            <p>
              ※半角カナは使用しないでください。また、数字項目はすべて半角でご記入ください。
            </p>
            <p>
              <strong>［ご注意］</strong>
              　他社製品・サービスなどのご案内やセールス、売り込みはご対応致しかねますのでご遠慮ください。
            </p>
            <table>
              <tbody>
                <tr>
                  <th>
                    <p>
                      <span className="contact_title">お問合せ区分</span>
                      <span className="contact_form-required">[必須]</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span className="font-bold">
                        製品・サービスに関するお問い合わせ
                      </span>
                      <br />
                      　↓製品・サービスをお選びください。
                      <br />
                      <span
                        className="wpcf7-form-control-wrap"
                        data-name="radio-716"
                      >
                        <span
                          className="wpcf7-form-control wpcf7-radio"
                          id="service-product"
                        >
                          <span className="wpcf7-list-item first">
                            <label>
                              <input
                                type="radio"
                                name="radio-716"
                                value="Cyber Smartシリーズ（※）"
                                checked={
                                  selectedOption === "Cyber Smartシリーズ（※）"
                                }
                                onChange={handleOptionChange}
                              />
                              <span className="wpcf7-list-item-label">
                                Cyber Smartシリーズ（※）
                              </span>
                            </label>
                          </span>
                          <span className="wpcf7-list-item">
                            <label>
                              <input
                                type="radio"
                                name="radio-716"
                                value="リバースエンジニアリングサービス／ドキュメント生成サービス"
                                checked={
                                  selectedOption ===
                                  "リバースエンジニアリングサービス／ドキュメント生成サービス"
                                }
                                onChange={handleOptionChange}
                              />
                              <span className="wpcf7-list-item-label">
                                リバースエンジニアリングサービス／ドキュメント生成サービス
                              </span>
                            </label>
                          </span>
                          <span className="wpcf7-list-item">
                            <label>
                              <input
                                type="radio"
                                name="radio-716"
                                value="マイグレーションサービス"
                                checked={
                                  selectedOption === "マイグレーションサービス"
                                }
                                onChange={handleOptionChange}
                              />
                              <span className="wpcf7-list-item-label">
                                マイグレーションサービス
                              </span>
                            </label>
                          </span>
                          <span className="wpcf7-list-item">
                            <label>
                              <input
                                type="radio"
                                name="radio-716"
                                value="システムインテグレーションサービス"
                                checked={
                                  selectedOption ===
                                  "システムインテグレーションサービス"
                                }
                                onChange={handleOptionChange}
                              />
                              <span className="wpcf7-list-item-label">
                                システムインテグレーションサービス
                              </span>
                            </label>
                          </span>
                          <span className="wpcf7-list-item">
                            <label>
                              <input
                                type="radio"
                                name="radio-716"
                                value="楽々セキュアコネクト"
                                checked={
                                  selectedOption === "楽々セキュアコネクト"
                                }
                                onChange={handleOptionChange}
                              />
                              <span className="wpcf7-list-item-label">
                                楽々セキュアコネクト
                              </span>
                            </label>
                          </span>
                          <span className="wpcf7-list-item">
                            <label>
                              <input
                                type="radio"
                                name="radio-716"
                                value="ポジナビ・ポジナビプラス"
                                checked={
                                  selectedOption === "ポジナビ・ポジナビプラス"
                                }
                                onChange={handleOptionChange}
                              />
                              <span className="wpcf7-list-item-label">
                                ポジナビ・ポジナビプラス
                              </span>
                            </label>
                          </span>
                          <span className="wpcf7-list-item">
                            <label>
                              <input
                                type="radio"
                                name="radio-716"
                                value="monday Work management"
                                checked={
                                  selectedOption === "monday Work management"
                                }
                                onChange={handleOptionChange}
                              />
                              <span className="wpcf7-list-item-label">
                                monday Work management
                              </span>
                            </label>
                          </span>
                          <span className="wpcf7-list-item">
                            <label>
                              <input
                                type="radio"
                                name="radio-716"
                                value="monday CRM"
                                checked={selectedOption === "monday CRM"}
                                onChange={handleOptionChange}
                              />
                              <span className="wpcf7-list-item-label">
                                monday CRM
                              </span>
                            </label>
                          </span>
                          <span className="wpcf7-list-item">
                            <label>
                              <input
                                type="radio"
                                name="radio-716"
                                value="ビジネスパートナーに関するお問い合わせ"
                                checked={
                                  selectedOption ===
                                  "ビジネスパートナーに関するお問い合わせ"
                                }
                                onChange={handleOptionChange}
                              />
                              <span className="wpcf7-list-item-label">
                                ビジネスパートナーに関するお問い合わせ
                              </span>
                            </label>
                          </span>
                          <span className="wpcf7-list-item last">
                            <label>
                              <input
                                type="radio"
                                name="radio-716"
                                value="その他のお問い合わせ"
                                checked={
                                  selectedOption === "その他のお問い合わせ"
                                }
                                onChange={handleOptionChange}
                              />
                              <span className="wpcf7-list-item-label">
                                その他のお問い合わせ
                              </span>
                            </label>
                          </span>
                        </span>
                      </span>
                    </p>
                    <p>
                      （※）Cyber Smartシリーズ(Cyber CTI／Cyber IP-PBX／Cyber
                      Phone／Cyber Tel／Cyber GW)
                    </p>
                  </td>
                </tr>

                <tr>
                  <th>
                    <p>
                      <span class="title">氏名</span>
                      <span class="contact_form-required">[必須]</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span
                        class="wpcf7-form-control-wrap"
                        data-name="your-name"
                      >
                        <input
                          size="40"
                          class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                          aria-required="true"
                          aria-invalid="false"
                          value={formData.name}
                          onChange={handleChange}
                          type="text"
                          name="name"
                        />
                        <br />
                        {formErrors.name && (
                          <span className="error-message">
                            {formErrors.name}
                          </span>
                        )}
                      </span>
                    </p>
                  </td>
                </tr>

                <tr>
                  <th>
                    <p>
                      <span class="title">貴社名</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span
                        class="wpcf7-form-control-wrap"
                        data-name="company-name"
                      >
                        <input
                          size="35"
                          class="wpcf7-form-control wpcf7-text"
                          aria-invalid="false"
                          value={formData.companyName}
                          onChange={handleChange}
                          type="text"
                          name="companyName"
                        />{" "}
                      </span>
                    </p>
                  </td>
                </tr>

                <tr>
                  <th>
                    <p>
                      <span class="title">部署名</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span
                        class="wpcf7-form-control-wrap"
                        data-name="unit-name"
                      >
                        <input
                          size="35"
                          class="wpcf7-form-control wpcf7-text"
                          aria-invalid="false"
                          value={formData.department}
                          onChange={handleChange}
                          type="text"
                          name="department"
                        />{" "}
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>
                      <span class="title">郵便番号</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span class="wpcf7-form-control-wrap" data-name="zip">
                        <input
                          size="10"
                          class="wpcf7-form-control wpcf7-text"
                          id="zip"
                          aria-invalid="false"
                          placeholder="例）123-4567"
                          value={formData.zipcode}
                          onChange={handleChange}
                          type="text"
                          name="zip"
                        />
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>
                      <span class="title">都道府県</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span class="wpcf7-form-control-wrap" data-name="pref">
                        <input
                          size="10"
                          class="wpcf7-form-control wpcf7-text"
                          id="pref"
                          aria-invalid="false"
                          value={formData.prefecture}
                          onChange={handleChange}
                          type="text"
                          name="pref"
                        />
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>
                      <span class="title">市区町村</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span class="wpcf7-form-control-wrap" data-name="addr1">
                        <input
                          size="40"
                          maxlength="100"
                          class="wpcf7-form-control wpcf7-text"
                          id="addr"
                          aria-invalid="false"
                          value={formData.city}
                          onChange={handleChange}
                          type="text"
                          name="addr1"
                        />
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>
                      <span class="title">丁目番地</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span class="wpcf7-form-control-wrap" data-name="addr2">
                        <input
                          size="40"
                          maxlength="100"
                          class="wpcf7-form-control wpcf7-text"
                          aria-invalid="false"
                          value={formData.street}
                          onChange={handleChange}
                          type="text"
                          name="addr2"
                        />
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>
                      <span class="title">ビル・マンション名</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span
                        class="wpcf7-form-control-wrap"
                        data-name="building-name"
                      >
                        <input
                          size="35"
                          class="wpcf7-form-control wpcf7-text"
                          aria-invalid="false"
                          value={formData.buildName}
                          onChange={handleChange}
                          type="text"
                          name="building-name"
                        />
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>
                      <span class="title">電話番号</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span class="wpcf7-form-control-wrap" data-name="tel-773">
                        <input
                          size="40"
                          class="wpcf7-form-control wpcf7-tel wpcf7-text wpcf7-validates-as-tel"
                          aria-invalid="false"
                          value={formData.tel}
                          onChange={handleChange}
                          type="tel"
                          name="tel-773"
                        />
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>
                      <span class="title">メールアドレス</span>
                      <span class="contact_form-required">[必須]</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span
                        class="wpcf7-form-control-wrap"
                        data-name="your-email"
                      >
                        <input
                          size="40"
                          class="wpcf7-form-control wpcf7-email wpcf7-validates-as-required wpcf7-text wpcf7-validates-as-email"
                          aria-required="true"
                          aria-invalid="false"
                          value={formData.email}
                          onChange={handleChange}
                          type="email"
                          name="email"
                        />{" "}
                        <br />
                        {formErrors.email && (
                          <span className="error-message">
                            {formErrors.email}
                          </span>
                        )}
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>
                      <span class="title">お問い合わせ内容</span>
                      <span class="contact_form-required">[必須]</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span
                        class="wpcf7-form-control-wrap"
                        data-name="your-message"
                      >
                        <textarea
                          cols="60"
                          rows="15"
                          class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                          aria-required="true"
                          aria-invalid="false"
                          name="details"
                          value={formData.details}
                          onChange={handleChange}
                        ></textarea>
                        <br />
                        {formErrors.details && (
                          <span className="error-message">
                            {formErrors.details}
                          </span>
                        )}
                      </span>
                    </p>
                  </td>
                </tr>
                <tr>
                  <th>
                    <p>
                      <span class="title">フォームご利用目的について</span>
                      <span class="contact_form-required">[必須]</span>
                    </p>
                  </th>
                  <td>
                    <p>
                      <span class="wpcf7-form-control-wrap" data-name="nosales">
                        <span class="wpcf7-form-control wpcf7-checkbox wpcf7-validates-as-required">
                          <span
                            class="wpcf7-list-item first last"
                            style={{ display: "flex" }}
                          >
                            <input
                              style={{ width: "auto" }}
                              type="checkbox"
                              name="purpose"
                              checked={formData.purpose}
                              onChange={handleChange}
                            />{" "}
                            <span class="wpcf7-list-item-label">
                              他社製品・サービスなどのご案内やセールス、売り込みではありません。
                            </span>
                          </span>
                          {formErrors.purpose && (
                            <span className="error-message">
                              {formErrors.purpose}
                            </span>
                          )}
                        </span>
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>

            <button className="contact_confirm" onClick={handleFormSubmit}>
              確認画面へ
            </button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contact;
