import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function Certification() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <h2 className="privacy_entry">
          情報セキュリティ<span>SECURITY</span>
        </h2>
        <div className="privacy1_box">
          <Link title="サイバーコム株式会社へ行く" to="/">
            ホーム
          </Link>
          　&nbsp;&gt;&nbsp; プライバシーマーク認証取得
        </div>
        <h3 className="pgtop">プライバシーマーク認証取得</h3>
        <div className="privacy_body">
          <div className="privacy_body">
            <p>
              当社は、個人情報に関する法令およびその他の規範を遵守し、ルールおよび体制を確立し、社会の健全な進展に貢献できるよう取り組んでおります。
            </p>
            <p>
              当社は、日本工業規格（JIS）であるJIS Q
              15001に基づいた個人情報保護マネジメントシステムを全社的に運用し、継続的な改善を行うことで、株主様、お客様、お取引先様などに満足のいただけるよう適切に個人情報を取り扱います。
            </p>
            <p>
              当社の個人情報保護マネジメントシステムは、2006年4月に一般財団法人
              日本情報経済社会推進協会（JIPDEC）のプライバシーマーク認定を取得しました。
            </p>
            <div className="frm03">
              <div className="frm03_left">
                <img
                  alt=""
                  src="	https://www.cy-com.co.jp/corporate/wp-content/files/p-mark.png"
                />
              </div>
              <div className="frm03_right">
                <table class="tb02">
                  <tbody>
                    <tr>
                      <th>JISQ15001</th>
                      <td>：2017</td>
                    </tr>
                    <tr>
                      <th>認定部署</th>
                      <td>：全社</td>
                    </tr>
                    <tr>
                      <th>認定番号</th>
                      <td>：第11820460号</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Certification;
