import React from "react";
import Footer from "./Footer";
import "../css/privacy.css";
import Header from "./Header";
import { Link } from "react-router-dom";

function Business() {
  return (
    <div className="main-container">
      <Header />
      <div className="privacy_box">
        <h2 className="privacy_entry business_img">
          ソフトウェア開発<span>SOFTWARE DEVELOPMENT</span>
        </h2>
        <div className="privacy1_box">
          <Link title="サイバーコム株式会社へ行く" to="/">
            ホーム
          </Link>
          　&nbsp;&gt;&nbsp; ソフトウェア開発
        </div>
        <p class="lead">
          <strong>
            お客様のニーズに応じた
            <span class="br">さまざまなソフトウェア開発に対応</span>
          </strong>
        </p>
        <h3 className="pgtop">ソフトウェア開発</h3>
        <div className="privacy_body">
          <div className="privacy_body">
            <div className="business_flex">
              <div className="business_flex_left">
                <p>
                  サイバーコムは、長年積み重ねた通信技術を活かし、制御や業務の分野まで幅広く手掛けるソフトウェア開発を主力事業としています。通信ソフトウェア開発、制御ソフトウェア開発、業務ソフトウェア開発など、さまざまな分野において、お客様が必要とするシステムの実現に向けて最大限の貢献を行い、今まで以上に私たちを取り巻く環境を便利で豊かにするためのたゆまぬ努力を続けます。
                </p>
              </div>
              <div className="business_flex_right">
                <img
                  alt=""
                  src="https://www.cy-com.co.jp/corporate/wp-content/files/soft_cybercom.png"
                />
              </div>
            </div>

            <div className="business1_box">
              <div className="business1_box_inner">
                <img
                  decoding="async"
                  src="https://www.cy-com.co.jp/corporate/wp-content/files/lnksoft01.png"
                  alt="通信ソフトウェア開発"
                />
                通信ソフトウェア開発
              </div>
              <div className="business1_box_inner">
                <img
                  decoding="async"
                  src="	https://www.cy-com.co.jp/corporate/wp-content/files/lnksoft02.png"
                  alt="制御ソフトウェア開発"
                />
                制御ソフトウェア開発
              </div>
              <div className="business1_box_inner">
                <img
                  decoding="async"
                  src="https://www.cy-com.co.jp/corporate/wp-content/files/lnksoft03.png"
                  alt="業務ソフトウェア開発"
                />
                業務ソフトウェア開発
              </div>
            </div>

            <h4>サイバーコムの取り組み</h4>

            <div className="business1_box">
              <div className="business1_box_inner">
                <img
                  decoding="async"
                  src="	https://www.cy-com.co.jp/corporate/wp-content/files/lnk_qua.png"
                  alt="品質への取り組み"
                />
                品質への取り組み
              </div>
              <div className="business1_box_inner">
                <img
                  decoding="async"
                  src="https://www.cy-com.co.jp/corporate/wp-content/files/lnkcmp10.png"
                  alt="情報セキュリティ"
                />
                情報セキュリティ
              </div>
              <div className="business1_box_inner">
                <img
                  decoding="async"
                  src="	https://www.cy-com.co.jp/corporate/wp-content/files/lnk_edu.png"
                  alt="技術者育成への取り組み"
                />
                技術者育成への取り組み
              </div>
            </div>

            <hr class="line01" />
            <p>
              ソフトウェア開発では、品質方針である「け（検査の徹底）・や（約束の厳守）・き（機密の保持）」の精神に基づき、高品質なソフトウェアをご提供します。
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;「けやき」は本店所在地である宮城県の県木にちなんでいます。
            </p>
            <p class="img100">
              <img
                width="802"
                height="192"
                class="shadow"
                src="	https://www.cy-com.co.jp/corporate/wp-content/files/soft_keyaki.jpg"
                alt="品質方針「け・や・き」"
              />
            </p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Business;
