import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [fixed, setFixed] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    setScrolled(scrollY > 1);
    setFixed(scrollY > 72);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="header__inner__left">
          <h1>サイバーコムテクノロジーで社会に貢献する</h1>
        </div>
        <div
          className={`header__inner ${fixed ? "fixed" : ""} ${
            scrolled ? "scrolled" : ""
          }`}
        >
          <div className="wrp-navi">
            <div className="fixed_txt_img">
              <img
                width="142"
                height="54"
                src="/head/head.png"
                alt="サイバーコム株式会社"
              />
            </div>
            <ul>
              <li>
                <Link to="/" onClick={toTop}>
                  家
                </Link>
              </li>
              <li>
                <Link to="/business" onClick={toTop}>
                  ソフトウェア開発
                </Link>
              </li>
              <li>
                <Link to="/privacy" onClick={toTop}>
                  個人情報保護
                </Link>
              </li>
              <li>
                <Link to="/contact" onClick={toTop}>
                  お問い合わせ
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-mail"
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                    <polyline points="22,6 12,13 2,6" />
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
