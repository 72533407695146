import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="foot_body">
          <div className="foot_internal">
            <h5>ソフトウェア開発</h5>
            <ul>
              <li>通信ソフトウェア開発</li>
              <li>制御ソフトウェア開発</li>
              <li>業務ソフトウェア開発</li>
            </ul>

            <h5>プロダクト</h5>
            <ul>
              <li>Cyber CTI</li>
              <li>Cyber IP-PBX</li>
              <li>Cyber Phone</li>
              <li>Cyber Tel</li>
              <li>Cyber GW</li>
              <li>クラウドサービス</li>
              <li>楽々セキュアコネクト</li>
              <li>Cyber Position Navi</li>
              <li>Cyber Position Navi Plus</li>
            </ul>
          </div>

          <div className="foot_internal">
            <h5>サービス</h5>
            <ul>
              <li>monday Work management</li>
              <li>monday CRM</li>
              <li>システムインテグレーション サービス</li>
              <li>リバースエンジニアリング サービス</li>
              <li>マイグレーションサービス</li>
              <li>ドキュメント生成サービス</li>
              <li>その他のサービス</li>
            </ul>
          </div>

          <div className="foot_internal">
            <h5>会社情報</h5>
            <ul>
              <li>トップメッセージ</li>
              <li>企業理念</li>
              <li>人事ポリシー</li>
              <li>沿革</li>
              <li>組織</li>
              <li>事業拠点</li>
              <li>グループ企業</li>
              <li>イベント・セミナー</li>
              <li>コーポレート・ガバナンス</li>
              <li>サステナビリティ</li>
              <li>一般事業主行動計画</li>
            </ul>
          </div>

          <div className="foot_internal">
            <h5>公開情報</h5>
            <ul>
              <li>ニュースリリース</li>
              <li>業績ハイライト</li>
              <li>過去の公開資料</li>
              <li>よくあるご質問</li>
              <li>電子公告</li>
            </ul>
          </div>

          <div className="foot_internal">
            <h5>品質への取り組み</h5>
            <ul>
              <li>
                <Link to="/security" onClick={toTop}>
                  情報セキュリティ
                </Link>
              </li>
              <li>
                <Link to="/certification" onClick={toTop}>
                  プライバシーマーク認証取得
                </Link>
              </li>
              <li>
                <Link to="/privacy" onClick={toTop}>
                  個人情報保護
                </Link>
              </li>
              <li>
                <Link to="/mynumber" onClick={toTop}>
                  特定個人情報等の取扱い
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <p class="foot-border-t ">
        Copyright 2024 Cyber Com Co., Ltd. All Rights Reserved.
      </p>
    </>
  );
}

export default Footer;
