import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import { Link } from "react-router-dom";

function Main() {
  return (
    <div className="main-container">
      <Header />
      <div className="mian1_box">
        <img
          alt=""
          src="https://www.cy-com.co.jp/corporate/wp-content/files/45th-anniversary.jpg"
        />
      </div>
      <div className="main2_box">
        <ul>
          <li>
            <img
              alt=""
              src="https://www.cy-com.co.jp/corporate/wp-content/files/2b376674abde7e3dfacdc7b9a553ce75.png"
            />
          </li>
          <li>
            <img
              alt=""
              src="	https://www.cy-com.co.jp/corporate/wp-content/files/ef379152af4492256336c66a629769b2.png"
            />
          </li>
          <li>
            <img
              alt=""
              src="	https://www.cy-com.co.jp/corporate/wp-content/files/4eb5cb5946b43a6f839f7f76be9f9858.png"
            />
          </li>
        </ul>
      </div>
      <div>
        <div className="main3_box">
          <h3>お知らせ</h3>
          <dl>
            <dt>
              <font _mstmutation="1">2024/10/24</font>
              <span>イベント</span>
            </dt>

            <dd>
              <Link to="">AWS生成AIハンズオンセミナーに登壇 </Link>
            </dd>

            <dt>
              <font _mstmutation="1">2024/10/01</font>
              <span>ニュースリリース</span>
            </dt>

            <dd>
              <Link to="/head/241001-1.pdf" target="_blank">
                サイバーコム、E-SPIN社との連携を通じてAPAC地域での開発も積極的に推進
                (196 KB)
              </Link>
            </dd>

            <dt>
              <font _mstmutation="1">2024/06/19</font>
              <span>ニュースリリース</span>
            </dt>

            <dd>
              <Link to="/head/240619-1.pdf" target="_blank">
                組織体制変更および人事異動に関するお知らせ (210 KB)
              </Link>
            </dd>
          </dl>

          <p className="main3_gotolnk">
            <Link to="/">お知らせ一覧へ</Link>
          </p>
        </div>

        <div className="main4_box">
          <ul>
            <li>
              <img
                alt=""
                src="	https://www.cy-com.co.jp/corporate/wp-content/files/tt6.png"
              />
              <div className="main4_ul_txt">お困りごとを解決</div>
            </li>
            <li>
              <img
                alt=""
                src="https://www.cy-com.co.jp/corporate/wp-content/files/tt1.png"
              />
              <div className="main4_ul_txt">東北エリアのお客様へ</div>
            </li>
            <li>
              <img
                alt=""
                src="	https://www.cy-com.co.jp/corporate/wp-content/files/tt2.png"
              />
              <div className="main4_ul_txt">北陸エリアのお客様へ</div>
            </li>{" "}
            <li>
              <img
                alt=""
                src="https://www.cy-com.co.jp/corporate/wp-content/files/tt3.png"
              />
              <div className="main4_ul_txt">関東エリアのお客様へ</div>
            </li>{" "}
            <li>
              <img
                alt=""
                src="	https://www.cy-com.co.jp/corporate/wp-content/files/tt4.png"
              />
              <div className="main4_ul_txt">東海エリアのお客様へ</div>
            </li>{" "}
            <li>
              <img
                alt=""
                src="	https://www.cy-com.co.jp/corporate/wp-content/files/tt5.png"
              />
              <div className="main4_ul_txt">九州エリアのお客様へ</div>
            </li>
          </ul>
        </div>
      </div>
      <div className="main5_box">
        <div className="main5_box_inner">
          <div className="main5_internal">
            <img
              alt=""
              src="https://www.cy-com.co.jp/corporate/wp-content/files/toplnk01.png"
            />
            <h4>ソフトウェア開発</h4>
            <p>
              長年積み重ねた通信技術を活かし制御や業務の分野まで幅広く手掛けています。
            </p>
          </div>
          <div className="main5_internal">
            <img
              alt=""
              src="https://www.cy-com.co.jp/corporate/wp-content/files/toplnk02.png"
            />
            <h4>プロダクト</h4>
            <p>経費削減や業務効率向上を図る、高品質な製品を提供しています。</p>
          </div>
          <div className="main5_internal">
            <img
              alt=""
              src="https://www.cy-com.co.jp/corporate/wp-content/files/toplnk03.png"
            />
            <h4>サービス</h4>
            <p>
              お客様のIT環境に関するあらゆるご相談・お悩みにお応えするサービスを提供しています。
            </p>
          </div>

          <div className="main5_internal">
            <img
              alt=""
              src="https://www.cy-com.co.jp/corporate/wp-content/files/toplnk04.png"
            />
            <h4>会社情報</h4>
            <p>
              ソフトウェア技術で、社会に、そして、お客様・株主様・社員に貢献します。
            </p>
          </div>
          <div className="main5_internal">
            <img
              alt=""
              src="	https://www.cy-com.co.jp/corporate/wp-content/files/toplnk05.png"
            />
            <h4>公開情報</h4>
            <p>各種公開資料、業績情報などをご紹介しています。</p>
          </div>
          <div className="main5_internal">
            <img
              alt=""
              src="	https://www.cy-com.co.jp/corporate/wp-content/files/toplnk06.png"
            />
            <h4>採用情報</h4>
            <p>
              ソフトウェア開発及びネットワーク・サーバ構築等幅広い分野のエンジニアを募集しています。
            </p>
          </div>
        </div>
      </div>
      <div className="main6_box">
        <div className="main6_box_inner">
          <h3> ソフトウェア開発</h3>
          <p className="main6_one_p">SOFTWARE DEVELOPMENT</p>
          <p className="main6_two_p">
            豊富な経験と常に進化する技術力で、多様化するお客様のニーズにご対応
          </p>
        </div>
      </div>
      <div className="main7_box">
        <div className="main7_box_inner">
          <div className="main7_internal">
            <img
              alt=""
              src="https://www.cy-com.co.jp/corporate/wp-content/files/lnk_sft01.png"
            />
            <h4>通信ソフトウェア開発</h4>
          </div>
          <div className="main7_internal">
            <img
              alt=""
              src="	https://www.cy-com.co.jp/corporate/wp-content/files/lnk_sft02.png"
            />
            <h4>制御ソフトウェア開発</h4>
          </div>
          <div className="main7_internal">
            <img
              alt=""
              src="https://www.cy-com.co.jp/corporate/wp-content/files/lnk_sft03.png"
            />
            <h4>業務ソフトウェア開発</h4>
          </div>
        </div>
      </div>
      <div className="main6_box main6_other_img">
        <div className="main6_box_inner">
          <h3> プロダクト</h3>
          <p className="main6_one_p">PRODUCTS</p>
          <p className="main6_two_p">
            経費削減や業務効率向上を図る、高品質な製品をご提供
          </p>
        </div>
      </div>
      <div className="main7_box">
        <div className="main7_box_inner">
          <div className="main7_internal">
            <img
              alt=""
              src="https://www.cy-com.co.jp/corporate/wp-content/files/lnk_cybercti.png"
            />
            <h4>高品質・低コストのコールセンターシステム</h4>
          </div>
          <div className="main7_internal">
            <img
              alt=""
              src="	https://www.cy-com.co.jp/corporate/wp-content/files/lnk_cyberippbx.png"
            />
            <h4>高機能・低価格オフィス電話システム</h4>
          </div>
          <div className="main7_internal">
            <img
              alt=""
              src="	https://www.cy-com.co.jp/corporate/wp-content/files/lnk_cyberphone.png"
            />
            <h4>スマートフォンをオフィスの内線電話に</h4>
          </div>
        </div>
      </div>
      <div className="main6_box main7_other_img">
        <div className="main6_box_inner">
          <h3> サービス</h3>
          <p className="main6_one_p">SERVICES</p>
          <p className="main6_two_p">
            お客様のIT環境に関するあらゆるご相談・お悩みに応えるサービスをご提供
          </p>
        </div>
      </div>
      <div className="main7_box">
        <div className="main7_box_inner">
          <div className="main7_internal">
            <img
              alt=""
              src="https://www.cy-com.co.jp/corporate/wp-content/files/lnk_svc01.png"
            />
            <h4>システムインテグレーションサービス</h4>
          </div>
          <div className="main7_internal">
            <img
              alt=""
              src="https://www.cy-com.co.jp/corporate/wp-content/files/lnk_svc04.png"
            />
            <h4>ドキュメント生成サービス</h4>
          </div>
          <div className="main7_internal">
            <img
              alt=""
              src="	https://www.cy-com.co.jp/corporate/wp-content/files/lnk_svc03.png"
            />
            <h4>リバースエンジニアリングサービス</h4>
          </div>
        </div>
      </div>

      <div className="main8_box">
        <div className="main8_box_left">
          <h3>採用情報</h3>
          <p className="main8_one_p">COMPANY</p>
        </div>
        <div className="main8_box_right">
          <h3>会社情報</h3>
          <p className="main8_one_p">RECRUIT</p>
          <p className="main8_two_p">
            技術と想いのある人たちと働きたい
            <br />
            サイバーコムが求めるのは《人財》です
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
